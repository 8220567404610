import React from 'react';
import { getCookie, setCookie } from '@ui/hooks/useCookie';
import { Button, Heading } from '@ui/components/shared';
import Link from 'next/link';

const AdultContentModal = () => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const hideModalCookie = getCookie('allowAdultContent');

  React.useEffect(() => {
    setShowModal(true);
  }, []);

  const hideModal = () => {
    setShowModal(false);
    setCookie('allowAdultContent', 'true');
  };

  if (!showModal || hideModalCookie) {
    return null;
  }

  return (
    <div className="w-full h-full fixed top-0 left-0 bg-white z-10">
      <div className="absolute left-0 bottom-6 px-3 w-full md:w-auto md:px-0 md:bottom-auto md:top-1/2 md:left-1/2 md:-translate-y-1/2 md:-translate-x-1/2">
        <div className="w-full bg-[#FCF3F6] rounded-lg overflow-hidden border border-black p-4 max-w-[29rem] mx-auto flex flex-col gap-y-6 md:p-8">
          <div>
            <Heading className="mb-3 text-xl" asChild>
              <p>Hey Girl,</p>
            </Heading>
            <Heading className="mb-4" asChild>
              <p>We want to make sure you&apos;re comfortable with this!</p>
            </Heading>
            <p>
              The following content contains sex related products and accessories including product images, information
              and usage guides. Are you sure you want to continue?
            </p>
          </div>
          <div className="flex flex-col gap-y-2">
            <Button onClick={hideModal}>Yes, Continue</Button>
            <Button className="h-11" variant="tertiary" asChild>
              <Link href="/">go back to the homepage</Link>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdultContentModal;
