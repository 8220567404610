import React from 'react';
import { Slot } from '@radix-ui/react-slot';
import cn from '@ui/utils/cn';

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  asChild?: boolean;
}

export default function Heading({ asChild, children, className }: HeadingProps): React.ReactElement {
  const Comp = asChild ? Slot : 'h2';

  return <Comp className={cn('uppercase leading-[1.55] font-bold text-black', className)}>{children}</Comp>;
}
